<template>
    <div id="pageTable">
        <v-container
            class="d-flex align-center justify-center"
            style="height: 80vh; width: 80vw"
            v-if="
                rol != 'root' &&
                rol != 'admin' &&
                rol != 'admin-empresa' &&
                rol != 'admin-sucursal' &&
                rol != 'admin-depto'
            "
        >
            <v-card class="" style="border-radius: 20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal" />
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn class="btnGuardar" @click="redireccionar()"> Ok </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container grid-list-xl fluid v-else>
            <v-row>
                <v-overlay :value="overlayDesgarga">
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img src="/static/icon/favicon.ico" :width="60"></v-img>
                        </v-progress-circular>
                        <p>{{ frase }}</p>
                    </div>
                </v-overlay>
                <v-col lg="12">
                    <v-overlay :value="dialogOverlayEmpleados" :absolute="true">
                        <div class="align-center justify-center text-center">
                            <v-progress-circular
                                indeterminate
                                :size="100"
                                :width="7"
                                color="#2DB9D1"
                            >
                                <v-img src="/static/icon/favicon.ico" :width="60"></v-img>
                            </v-progress-circular>
                            <p class="mt-5">
                                {{ "Subiendo empleados" }} - {{ empleadosPorcentaje }} %
                            </p>
                            <p>
                                Procesados {{ empleadosProcesados }} de {{ empleadosTotal }}
                            </p>
                        </div>
                    </v-overlay>
                    <data-table
                        ref="tabla"
                        :tableName="'Empleados'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :perPage="[10, 25, 50, 100]"
                    >
                        <template
                            v-if="
                                rol == 'root' ||
                                rol == 'admin' ||
                                rol == 'admin-empresa' ||
                                rol == 'admin-sucursal'
                            "
                            slot="actionButtonsLeft"
                        >
                            <v-menu offset-y content-class="menuClassAcciones" v-if="permisos.create">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="#004BAF"
                                        class="btnAdd mr-3"
                                        elevation="0"
                                        right
                                        rounded
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        Agregar
                                        <template v-for="(item, valor) in attrs">
                                            <v-icon
                                                :key="valor"
                                                v-if="valor == 'aria-expanded' && item == 'true'"
                                                class="pl-2"
                                                color="#FFFFFF"
                                            >
                                                expand_less
                                            </v-icon>
                                            <v-icon
                                                :key="valor"
                                                v-if="valor == 'aria-expanded' && item == 'false'"
                                                class="pl-2"
                                                color="#FFFFFF"
                                            >
                                                expand_more
                                            </v-icon>
                                        </template>
                                        <!-- <v-icon dark class="mr-2">add</v-icon>Agregar
                                                        <div class="mr-4"></div> -->
                                    </v-btn>
                                </template>
                                <div class="btnAddContenedor">
                                    <template>
                                        <div
                                            @click="abrirModalEmpleado('create')"
                                            class="btnAccionesItem py-2"
                                        >
                                            <span>Empleado asalariado</span>
                                        </div>
                                        <div
                                            @click="abrirModalEmpleadoAsimilado('create')"
                                            class="btnAccionesItem py-2"
                                        >
                                            <span>Empleado asimilado</span><br />
                                        </div>
                                    </template>
                                </div>
                            </v-menu>
                        </template>

                        <template
                            v-if="
                                rol == 'root' ||
                                rol == 'admin' ||
                                rol == 'admin-empresa' ||
                                rol == 'admin-sucursal'
                            "
                            slot="actionButtonsLeft"
                        >
                            <v-menu offset-y content-class="menuClassAcciones">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        class="btnAcciones pr-1 mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        elevation="0"
                                    >
                                        Acciones
                                        <template v-for="(item, valor) in attrs">
                                            <v-icon
                                                :key="valor"
                                                v-if="valor == 'aria-expanded' && item == 'true'"
                                                class="pl-2"
                                                color="#FFFFFF"
                                            >
                                                expand_less
                                            </v-icon>
                                            <v-icon
                                                :key="valor"
                                                v-if="valor == 'aria-expanded' && item == 'false'"
                                                class="pl-2"
                                                color="#FFFFFF"
                                            >
                                                expand_more
                                            </v-icon>
                                        </template>
                                    </v-btn>
                                </template>
                                <div class="btnAccionesContenedor">
                                    <div class="btnAccionesTitulo pb-2">
                                        <span>Layout de empleados</span>
                                    </div>

                                    <template v-if="rol == 'root' || rol == 'admin'">
                                        <div
                                            @click="abrirModalLayoutEmpleados('descargar')"
                                            class="btnAccionesItem py-2"
                                        >
                                            <span>Descargar layout</span>
                                        </div>
                                        <div
                                            @click="abrirModalLayoutEmpleados('subir')"
                                            class="btnAccionesItem py-2"
                                        >
                                            <span>Importar layout para agregar empleados</span><br />
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div @click="layout()" class="btnAccionesItem py-2">
                                            <span>Descargar layout</span>
                                        </div>
                                        <div
                                            @click="modalLayout('importar')"
                                            class="btnAccionesItem py-2"
                                        >
                                            <span>Importar layout para agregar empleados</span><br />
                                        </div>
                                    </template>

                                    <div class="btnAccionesTitulo pt-6 pb-2">
                                        <span>Layout con información de los empleados</span>
                                    </div>
                                    <template v-if="rol == 'root' || rol == 'admin'">
                                        <div
                                            @click="abrirModalLayoutEmpleados('exportar')"
                                            class="btnAccionesItem py-2"
                                        >
                                            <span>Exportar carga masiva</span>
                                        </div>
                                        <div
                                            @click="abrirModalLayoutEmpleados('exportar-asimilado')"
                                            class="btnAccionesItem py-2"
                                        >
                                            <span>Exportar asimilados</span>
                                        </div>
                                        <div
                                            @click="abrirModalLayoutEmpleados('actulizacionMasivo')"
                                            class="btnAccionesItem py-2"
                                        >
                                            <span>Actualizar carga masiva</span><br />
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div @click="exportar()" class="btnAccionesItem py-2">
                                            <span>Exportar carga masiva</span>
                                        </div>
                                        <div
                                            @click="
                                                asimilados = true;
                                                exportar();
                                            "
                                            class="btnAccionesItem py-2"
                                        >
                                            <span>Exportar asimilados</span>
                                        </div>
                                        <div
                                            @click="modalLayout('actulizacionMasivo')"
                                            class="btnAccionesItem py-2"
                                        >
                                            <span>Actualizar carga masiva</span>
                                        </div>
                                    </template>
                                </div>
                            </v-menu>
                        </template>
                        <template slot="filters">
                            <v-col xs="12" sm="12" md="12">
                                <div class="RangoIngreso">
                                    <span>Rango de fechas de ingreso</span>
                                </div>
                            </v-col>

                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_inicio"
                                    label="Fecha inicio"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaInicio"
                                />
                            </v-col>

                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_final"
                                    label="Fecha final"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaFinal"
                                />
                            </v-col>

                            <v-col xs="12" sm="12" md="12" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="6" class="py-0">
                                <v-text-field outlined label="RFC" v-model="rfc"></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="6" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Correo"
                                    v-model="correo"
                                ></v-text-field>
                            </v-col>

                            <template v-if="rol == 'root'">
                                <v-col xs="12" sm="12" md="12" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="clientes"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Cliente"
                                        persistent-hint
                                        v-model="cliente_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Registro Patronal"
                                    :items="registroPatronal"
                                    item-text="nombreClave"
                                    item-value="id"
                                    v-model="registroPatronal_value"
                                >
                                </v-autocomplete>
                            </v-col>

                            <template v-if="rol == 'root' || rol == 'admin'">
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="empresas"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Empresa"
                                        persistent-hint
                                        v-model="empresa_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template
                                v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'"
                            >
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="sucursales"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Sucursal"
                                        persistent-hint
                                        v-model="sucursal_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template
                                v-if="
                                rol == 'root' ||
                                rol == 'admin' ||
                                rol == 'admin-empresa' ||
                                rol == 'admin-sucursal'
                                "
                            >
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="departamentos"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Departamento"
                                        v-model="departamento_value"
                                    />
                                </v-col>
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="tiposNominas"
                                        item-text="claveDescripcion"
                                        item-value="id"
                                        label="Tipo de nómina"
                                        v-model="tiposNominas_value"
                                    />
                                </v-col>
                            </template>
                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsTiposEmpleados"
                                    item-text="descripcion"
                                    item-value="valor"
                                    label="Tipo de empleado"
                                    v-model="searchTipoEmpleado"
                                />
                            </v-col>
                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsCategoriasEmpleado"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Categoría de empleado"
                                    v-model="searchCategoriaEmpleado"
                                />
                            </v-col>
                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsCentroCosto"
                                    item-text="descripcion"
                                    item-value="id"
                                    label="Centro de costo"
                                    v-model="searchCentroDeCosto"
                                />
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td
                                    style="
                                        min-width: 159px !important;
                                        max-width: 161px !important;
                                        width: 160px;
                                        padding-right: 4px;
                                    "
                                >
                                    <div class="tblPrincipal">{{ item.clave }}</div>
                                </td>
                                <td @click="detalleEmpleado(item)" class="cursor-pointer">
                                    <div style="max-width: 30rem">
                                        <v-list color="transparent" class="py-0 my-0">
                                            <v-list-item class="py-0 my-0 px-0">
                                                <v-list-item-avatar class="py-0 my-0">
                                                    <v-img
                                                        class="py-0 my-0"
                                                        :src="urlImg + item.foto"
                                                    ></v-img>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="ml-0 py-0 my-0">
                                                    <v-list-item-title class="tblPrincipal text-wrap">
                                                        {{item.nombre_completo}}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </td>
                                <td>
                                    <div>{{ item.correo }}</div>
                                </td>
                                <td>
                                    <div class="tblCenter">
                                        <v-rating v-model="item.objRankig.length" size="24" dense>
                                            <template v-slot:item="props">
                                                <v-tooltip bottom v-if="props.isFilled">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :color="props.isFilled ? '#2DB9D1' : '#F2F2F2'"
                                                            class="mx-1"
                                                        >
                                                            {{ props.isFilled ? "mdi-star" : "mdi-star" }}
                                                        </v-icon>
                                                    </template>
                                                    <span v-if="props.isFilled">
                                                        {{item.objRankig[props.index]}}
                                                    </span>
                                                </v-tooltip>

                                                <v-icon
                                                    v-else
                                                    :color="props.isFilled ? '#2DB9D1' : '#F2F2F2'"
                                                    class="mx-1"
                                                >
                                                    {{ props.isFilled ? "mdi-star" : "mdi-star" }}
                                                </v-icon>
                                            </template>
                                        </v-rating>
                                    </div>
                                </td>
                                <td
                                    style="
                                        min-width: 290px !important;
                                        max-width: 301px !important;
                                        width: 300px;
                                        padding-left: 8px;
                                        padding-right: 8px;
                                    "
                                >
                                    <div class="tblOpciones">
                                        <v-menu
                                            buttom
                                            left
                                            class="elevation-0 d-inline"
                                            content-class="mt-0 menuClassAccionesEmpleado"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                    class="elevation-0"
                                                >
                                                    <template v-for="(item, valor) in attrs">
                                                        <v-icon
                                                            :key="valor"
                                                            v-if="valor == 'aria-expanded' && item == 'false'"
                                                            class="pl-2"
                                                            color="#828282"
                                                        >
                                                            more_vert
                                                        </v-icon>
                                                        <v-icon
                                                            :key="valor"
                                                            v-if="valor == 'aria-expanded' && item == 'true'"
                                                            class="pl-2"
                                                            color="#2DB9D1"
                                                        >
                                                            more_vert
                                                        </v-icon>
                                                    </template>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item @click="openModalInfo(item)">
                                                    <v-list-item-title>
                                                        Ver datos del empleado
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    @click="
                                                        item.asimilado
                                                        ? abrirModalEmpleadoAsimilado('update', item)
                                                        : abrirModalEmpleado('update', item)
                                                    "
                                                    v-if="
                                                        rol == 'root' ||
                                                        rol == 'admin' ||
                                                        rol == 'admin-empresa' ||
                                                        rol == 'admin-sucursal'
                                                    "
                                                >
                                                    <v-list-item-title>
                                                        Editar empleado
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <template v-if="permisos.delete">
                                                    <v-list-item
                                                        @click="abrirModal('baja', item)"
                                                        v-if="
                                                            rol == 'root' ||
                                                            rol == 'admin' ||
                                                            rol == 'admin-empresa' ||
                                                            rol == 'admin-sucursal'
                                                        "
                                                    >
                                                        <v-list-item-title>
                                                            Dar de baja
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                                <v-list-item
                                                    @click="abrirModalClave(item)"
                                                    v-if="!item.asimilado"
                                                >
                                                    <v-list-item-title>
                                                        Clave checador
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    @click="detalle_documentos(item)"
                                                >
                                                    <v-list-item-title>
                                                        Documentos
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-tooltip bottom v-if="permisos.delete">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-if="
                                                        rol == 'root' ||
                                                        rol == 'admin' ||
                                                        rol == 'admin-empresa' ||
                                                        rol == 'admin-sucursal'
                                                    "
                                                    v-on="on"
                                                    @click="eliminar(item)"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                >
                                                    <v-icon
                                                        class="tamIconoBoton invertirColorBotonesDelete"
                                                    >
                                                        delete
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>

        <template>
            <v-dialog v-model="dialog" persistent :max-width="tamModal">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>

                <!-- card de baja de empleado -->
                <v-card>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="justify-center titulo mb-0 pt-50px">
                            <div class="headerModal">
                                <img :src="imagen" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModal }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="px-50px">
                            <div id="padre" v-show="isLoading">
                                <div id="loading">
                                    <v-progress-circular
                                        indeterminate
                                        :size="100"
                                        :width="7"
                                        color="blue"
                                    ></v-progress-circular>
                                </div>
                            </div>

                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <v-text-field
                                            outlined
                                            label="Nombre"
                                            class="pa-0 ma-0"
                                            v-model="nombreBaja"
                                            disabled
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <VuetifyDatePicker
                                            outlined
                                            v-model="baja.fecha"
                                            label="Fecha de baja"
                                            placeholder="dd/mm/aaaa"
                                            rules="required"
                                            ref="FechaBaja"
                                        />
                                    </v-col>
                                    <v-col
                                        xs="12"
                                        sm="12"
                                        md="12"
                                        class="py-0"
                                        v-if="!datosEmpleado.asimilado"
                                    >
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            name="Tipo de baja"
                                            rules="required"
                                        >
                                            <v-autocomplete
                                                outlined
                                                :items="tipos_baja_imss"
                                                item-text="descripcion"
                                                item-value="id"
                                                label="Tipo de baja ante el IMSS"
                                                persistent-hint
                                                v-model="baja.tipo_baja_imss_id"
                                                :error-messages="errors"
                                                required
                                            ></v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            name="Motivo de la baja"
                                            rules="required"
                                        >
                                            <v-textarea
                                                class="estiloLetras"
                                                v-model="baja.motivo"
                                                label="Motivo de la Baja"
                                                outlined
                                                :error-messages="errors"
                                                required
                                            ></v-textarea>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()"
                                :disabled="isSaving"
                            >
                                Cerrar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="bajaEmpleado()"
                                :disabled="invalid"
                                :loading="isSaving"
                            >
                                Baja
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>

        <template>
            <v-dialog v-model="dialogLayout" persistent max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        style="display: none"
                        class="botonModal"
                        id="btnModalLayout"
                    ></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                            <div class="headerModalStart">
                                <h2 class="titleModal_700_18">{{ titulo_modal_doc }}</h2>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <div id="padre" v-show="isLoading">
                                <div id="loading">
                                    <v-progress-circular
                                        indeterminate
                                        :size="100"
                                        :width="7"
                                        color="blue"
                                    ></v-progress-circular>
                                </div>
                            </div>

                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            name="Layout"
                                            rules="required"
                                        >
                                            <v-file-input
                                                outlined
                                                label="Seleccionar el documento"
                                                v-model="archivo.archivo"
                                                accept=".xlsx"
                                                required
                                                :error-messages="errors"
                                                prepend-icon=""
                                                append-icon="attach_file"
                                                id="fileDocument"
                                                @click:append="open"
                                            >
                                            </v-file-input>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()"
                                :disabled="isSaving"
                            >
                                Cerrar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                v-if="accionLayout === 'importar'"
                                @click="guardarLayout2()"
                                :disabled="invalid"
                                :loading="isSaving"
                            >
                                Subir
                            </v-btn>
                            <v-btn
                                class="btnGuardar"
                                v-if="accionLayout === 'actulizacionMasivo'"
                                @click="actulizacionMasivo()"
                                :disabled="invalid"
                                :loading="isSaving"
                            >
                                Actualizar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>

        <template>
            <v-dialog v-model="dialogLayoutEmpleados" persistent max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        style="display: none"
                        class="botonModal"
                        id="btnModalLayoutEmpleados"
                    ></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                            <div class="headerModalStart">
                                <h2 class="titleModal_700_18">{{ tituloLayoutEmpleados }}</h2>
                            </div>
                        </v-card-title>
                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoading">
                                <div id="loading">
                                    <v-progress-circular
                                        indeterminate
                                        :size="100"
                                        :width="7"
                                        color="blue"
                                    ></v-progress-circular>
                                </div>
                            </div>

                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col
                                        v-if="rol == 'root'"
                                        cols="12"
                                        xs="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        class="py-0"
                                    >
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            name="Cliente"
                                            rules="required"
                                        >
                                            <v-autocomplete
                                                outlined
                                                label="Cliente"
                                                :items="clientes"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="cliente_value"
                                                required
                                                :error-messages="errors"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col
                                        v-if="rol == 'root' || rol == 'admin'"
                                        cols="12"
                                        xs="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        class="py-0"
                                    >
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            name="Empresa"
                                            rules="required"
                                        >
                                            <v-autocomplete
                                                outlined
                                                label="Empresa"
                                                :items="empresas"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="empresa_value"
                                                required
                                                :error-messages="errors"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col
                                        v-if="
                                        accionLayout === 'subir' ||
                                        accionLayout === 'actulizacionMasivo'
                                        "
                                        cols="12"
                                        xs="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        class="py-0"
                                    >
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            name="Layout"
                                            rules="required"
                                        >
                                            <v-file-input
                                                outlined
                                                label="Seleccionar el documento"
                                                v-model="archivo.archivo"
                                                accept=".xlsx"
                                                required
                                                :error-messages="errors"
                                                prepend-icon=""
                                                append-icon="attach_file"
                                                id="fileDocument"
                                                @click:append="open"
                                            >
                                            </v-file-input>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()"
                                :disabled="isSaving"
                            >
                                Cerrar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                v-if="accionLayout === 'subir'"
                                @click="guardarLayout2()"
                                :disabled="invalid"
                                :loading="isSaving"
                            >
                                Subir
                            </v-btn>
                            <v-btn
                                class="btnGuardar"
                                v-if="accionLayout === 'descargar'"
                                @click="layout()"
                                :disabled="invalid"
                                :loading="isSaving"
                            >
                                Descargar
                            </v-btn>
                            <v-btn
                                class="btnGuardar"
                                v-if="
                                accionLayout === 'exportar' ||
                                accionLayout === 'exportar-asimilado'
                                "
                                @click="exportar()"
                                :disabled="invalid"
                                :loading="isSaving"
                            >
                                Exportar
                            </v-btn>
                            <v-btn
                                class="btnGuardar"
                                v-if="accionLayout === 'actulizacionMasivo'"
                                @click="actulizacionMasivo()"
                                :disabled="invalid"
                                :loading="isSaving"
                            >
                                Actualizar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
        <template>
            <v-row class="justify-center">
                <v-dialog v-model="dialog_info" scrollable persistent max-width="720px">
                    <v-card v-if="showDialoginfo">
                        <v-card-title class="justify-center">
                            <div class="col-lg-12">
                                <v-btn
                                    @click="cerrarModal()"
                                    small
                                    slot="activator"
                                    icon
                                    text
                                    class="v-btn-cerrar modal-pull-right"
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            </div>
                            <v-container>
                                <div class="col-lg-12 d-flex">
                                    <div class="mt-12">
                                        <div class="header">
                                            <h2 class="title">DATOS DEL EMPLEADO</h2>
                                        </div>

                                        <div class="d-flex">
                                            <v-divider class="divider-blue"></v-divider>
                                            <v-divider class="divider-gray"></v-divider>
                                        </div>

                                        <div class="row col-lg-12 pb-0 pt-7">
                                            <p class="py-0 px-0 subtitle mr-2">Nombre:</p>
                                            <p class="py-0 px-0 text" v-if="objEmpleado != null">
                                                {{ objEmpleado.nombre }}
                                                {{ objEmpleado.apellido_paterno }}
                                                {{ objEmpleado.apellido_materno }}
                                            </p>
                                        </div>

                                        <div class="row col-lg-12 py-0 mt-n1">
                                            <p class="py-0 px-0 subtitle mr-2">Tipo de empleado:</p>
                                            <p class="py-0 px-0 text" v-if="objEmpleado != null">
                                                {{ objEmpleado.asimilado ? "Asimilado" : "Asalariado" }}
                                            </p>
                                        </div>
                                        <div class="row col-lg-12 py-0 mt-n1">
                                            <p class="py-0 px-0 subtitle mr-2">Clave:</p>
                                            <p class="py-0 px-0 text" v-if="objEmpleado != null">
                                                {{ objEmpleado.clave }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="imgMarco">
                                        <img class="imgFoto" :src="fotoModal" />
                                    </div>
                                </div>
                                <div class="col-lg-12 d-flex mt-5">
                                    <v-divider class="divider-gray skiny"></v-divider>
                                </div>
                            </v-container>
                        </v-card-title>
                        <v-card-text>
                            <perfect-scrollbar style="max-height: 100%">
                                <component-info
                                    :objEmpleado="objEmpleado"
                                    :key="'B' + countInfoKardex"
                                >
                                </component-info>
                            </perfect-scrollbar>
                        </v-card-text>

                        <v-card-actions> </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>

        <template class="borde-card">
            <v-dialog
                v-model="dialogErrores"
                persistent
                max-width="950px"
                class="borde-card"
            >
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalErrores"></v-btn>
                </template>
                <v-card class="borde-card">
                    <v-card-title
                        class="padding_izquierdo padding_derecho padding_arriba"
                    >
                        <v-btn
                            @click="cerrarModalErrores()"
                            small
                            slot="activator"
                            icon
                            text
                            class="v-btn-cerrar modal-pull-right"
                            absolute
                            top
                            right
                        >
                            <v-icon class="icono-cerrar"></v-icon>
                        </v-btn>
                        <div class="headerModalStart">
                            <h2 class="titleModalErrores">Errores de empleados</h2>
                        </div>
                    </v-card-title>

                    <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                        <div id="padre" v-show="isLoadingError">
                            <div id="loading">
                                <v-progress-circular
                                    indeterminate
                                    :size="100"
                                    :width="7"
                                    color="blue"
                                ></v-progress-circular>
                            </div>
                        </div>

                        <v-container v-show="!isLoadingError" grid-list-md id="contenedor">
                            <v-row class="pa-0 ma-0">
                                <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                    <table class="tbl-errores">
                                        <thead class="tbl-header">
                                            <tr>
                                                <th
                                                    style="border-top-left-radius: 20px"
                                                    class="pl-7 errorth"
                                                >
                                                    Empleado
                                                </th>
                                                <th
                                                    style="border-top-right-radius: 20px"
                                                    class="errorth"
                                                >
                                                    Tipo de error
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="erroresModal.length == 0">
                                                <td class="emptyTableErrores" colspan="2">
                                                    No hay elementos para mostrar
                                                </td>
                                            </tr>
                                            <template v-else v-for="(error, index) in erroresModal">
                                                <template>
                                                    <tr :key="index">
                                                        <td class="pl-7 errortd pt-4 pb-0">
                                                            <div class="textoTablaError01">
                                                                {{
                                                                error.nombre_s +
                                                                " " +
                                                                error.apellido_paterno +
                                                                " " +
                                                                (error.apellido_materno != null
                                                                    ? error.apellido_materno
                                                                    : "")
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td class="errortd">
                                                            <div class="textoTablaError03">
                                                                {{ error.observaciones }}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr :key="'A' + index" class="trDivider">
                                                        <div class="my-2"></div>
                                                    </tr>
                                                </template>
                                            </template>
                                        </tbody>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>

        <template>
            <v-row class="justify-center">
                <v-dialog
                    v-model="dialog_empleado"
                    scrollable
                    persistent
                    max-width="900px"
                >
                    <v-card>
                        <v-card-text class="pa-0 ma-0">
                            <ModalEmpleado
                                :title="tituloEmpleado"
                                :objEmpleado="datosEmpleado"
                                :action="accionEmpleado"
                                @cerrarModal="cerrarModalEmpleado"
                                :key="'A' + componentKey"
                            />
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <v-row class="justify-center">
                <v-dialog
                    v-model="dialogEmpleadoAsimilado"
                    scrollable
                    persistent
                    max-width="900px"
                >
                    <v-card>
                        <v-card-text class="pa-0 ma-0">
                            <ModalEmpleadoAsimilado
                                :title="tituloEmpleado"
                                :objEmpleado="datosEmpleado"
                                :action="accionEmpleado"
                                @cerrarModal="cerrarModalEmpleado"
                                :key="'A' + componentKey"
                            />
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>

        <template>
            <v-dialog v-model="dialogClave" persistent max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        style="display: none"
                        class="botonModal"
                        id="btnModalLayout"
                    ></v-btn>
                </template>
                <v-card>
                    <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                        <div class="headerModalStart">
                            <h2 class="titleModal_700_18">Clave checador</h2>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <div id="padre" v-show="isLoadingChecador">
                            <div id="loading">
                                <v-progress-circular
                                    indeterminate
                                    :size="100"
                                    :width="7"
                                    color="blue"
                                ></v-progress-circular>
                            </div>
                        </div>

                        <v-container
                            v-show="!isLoadingChecador"
                            grid-list-md
                            id="contenedor"
                        >
                            <v-divider class="divider"></v-divider>
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                    <table class="ml-3">
                                        <tr>
                                            <td class="textoTablaClave01">Clave del empleado:</td>
                                            <td class="textoTablaClave02">
                                                {{ claveEmpleado.clave }}
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td class="textoTablaClave01">Clave de seguridad:</td>
                                            <td class="textoTablaClave02">
                                                {{
                                                claveEmpleado.claveVerificacion
                                                    ? claveEmpleado.claveVerificacion
                                                    : "----"
                                                }}
                                            </td>
                                            <td>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            v-on="on"
                                                            class="botonHover"
                                                            icon
                                                            fab
                                                            dark
                                                            small
                                                            @click="generarClave()"
                                                        >
                                                            <v-icon class="tamIconoBoton iconoDelgadoBoton"
                                                            >
                                                                autorenew
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Generar</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end card_accion">
                        <button class="btnCerrar" @click="cerrarModalClave()">
                        Cerrar
                        </button>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </div>
</template>

<script>
    import Vue from "vue";
    import Datatable from "@/components/datatable/Datatable.vue";
    import ComponentOne from "./Modal/ModalKardex.vue";
    import ComponentInfo from "./Modal/ModalKardexInfo.vue";
    import empleadosApi from "@/api/empleados";
    import clientesApi from "@/api/clientes";
    import apiTipoBajaImss from "../../api/nominas/tipoBajaImss";
    import apiRegistroPatronal from "@/api/nominas/registroPatronal";
    import empresaApi from "@/api/empresas";
    import sucursalApi from "@/api/sucursales";
    import departamentoApi from "@/api/departamentos";
    import Notify from "@/plugins/notify";
    import GetUrlImg from "@/plugins/Urlimagen";
    import VuetifyDatePicker from "@/components/VuetifyDatePicker.vue";
    import ModalEmpleado from "./Modal/ModalKardex.vue";
    import ModalEmpleadoAsimilado from "./Modal/ModalKardexAsimilado.vue";
    import apiTipoNomina from "@/api/nominas/tipoNomina";
    import apiRelojChecador from "@/api/registros";
    import apiCategoriaEmpleado from '@/api/categoriaEmpleado';
    import apiCentroCosto   from '@/api/nominas/centroCostos';

    import env from "@/plugins/enviroment";
    import Echo from "laravel-echo";
    window.Pusher = require("pusher-js");

    export default {
        components: {
            VuetifyDatePicker,
            "data-table": Datatable,
            ComponentInfo,
            ModalEmpleado,
            ModalEmpleadoAsimilado,
        },
        data() {
            return {
                url: "empleados/find",
                columns: [
                {
                    label: "Clave",
                    name: "clave",
                    filterable: true,
                },
                {
                    label: "Nombre",
                    name: "nombre",
                    filterable: true,
                },
                {
                    label: "Correo",
                    name: "correo",
                    filterable: false,
                },
                {
                    label: "Ranking",
                    name: "raking",
                    align: "center",
                    filterable: false,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    align: "center",
                    filterable: false,
                },
                ],
                filters: {
                    rol_name        : null,
                    usuario_id      : null,
                    activo          : true,
                    paginate        : true,
                    include_padres  : true,
                    cliente_id      : null,
                    empresa_id      : null,
                    sucursal_id     : null,
                    departamento_id : null,
                    order_by        : "clave",
                    tipo_empleado   : "ambos",
                },
                advancedSearch: {
                    nombre          : null,
                    direccion       : null,
                    codigo_postal   : null,
                    empresa_id      : null,
                    estado_id       : null,
                    ciudad_id       : null,
                    cliente_id      : null,
                    empleado_correo : null,
                },
                objEmpleado         : null,
                tableData           : {},
                isLoading           : true,
                paginationData      : {
                numberItems     : 10,
                numberPage      : 1,
                },
                datosLogin          : null,
                rol                 : null,
                accion              : null,
                tituloModal         : null,
                dialog              : false,
                isSaving            : false,
                calendarBaja        : false,
                baja                : {
                    id                  : null,
                    tipo_baja_imss_id   : null,
                    rol_name            : null,
                    accion              : "Baja",
                    motivo              : "",
                    fecha               : "",
                    usuario_id          : null,
                    email               : "",
                },
                nombreBaja          : "",
                urlImg              : null,
                tamModal            : "500px",
                imagen              : "/static/modal/empleadoBaja.svg",
                dialogLayout        : false,
                loading_guardar     : false,
                nombre_archivo      : [],
                archivo             : {
                    archivo         : [],
                    cliente_id      : null,
                    empresa_id      : null,
                    sucursal_id     : null,
                    rol_name        : null,
                    tipo            : "nominas",
                },
                empleado_ar         : null,
                imageUrl            : "",
                titulo_modal_doc    : "Subir layout para agregar empleados",
                menu_inicio         : false,
                menu_final          : false,
                fecha_inicio        : null,
                fecha_final         : null,
                menu_inicio_c       : false,
                menu_final_c        : false,
                fecha_inicio_c      : null,
                fecha_final_c       : null,
                clientes            : [],
                empresas            : [],
                sucursales          : [],
                departamentos       : [],
                tiposNominas        : [],
                registroPatronal    : [],
                cliente_value       : null,
                empresa_value       : null,
                sucursal_value      : null,
                departamento_value  : null,
                tiposNominas_value  : null,
                tipos_baja_imss     : [],
                nombre              : null,
                rfc                 : null,
                correo              : null,
                registroPatronal_value: null,
                departamento_id     : null,
                empleado            : {
                    id                  : 0,
                    nombre              : null,
                    apellido_paterno    : null,
                    apellido_materno    : null,
                    fecha_nacimiento    : null,
                    sexo                : "N",
                    direccion           : null,
                    estado_id           : null,
                    ciudad_id           : null,
                    codigo_postal       : null,
                    telefono            : "",
                    correo              : null,
                    estado_civil_id     : null,
                    fecha_ingreso       : null,
                    fecha_baja          : "",
                    dia_descanso        : "0",
                    cliente_id          : null,
                    empresa_id          : null,
                    sucursal_id         : null,
                    departamento_id     : null,
                    horario_id          : null,
                    prestacion_id       : null,
                    tiene_hijos         : false,
                    puesto              : null,
                    clave_interna       : null,
                    email_anterior      : null,
                    dias_vacaciones     : null,
                    foto                : null,
                    foto_default        : null,

                    //Nuevos parametros
                    puesto_id           : null,
                    nivel_estudios_id   : null,
                    cp_id               : null,
                    colonia_id          : null,
                    mejora_continua     : false,
                    ambiente_laboral    : false,
                },
                dialog_info             : false,
                dialogLayoutEmpleados   : false,
                tituloLayoutEmpleados   : "Descargar layout para agregar empleados",
                accionLayout            : "descargar",
                archivoLayout           : [],
                fotoModal               : null,
                datosEmpleado           : null,
                tituloEmpleado          : null,
                accionEmpleado          : null,
                dialog_empleado         : false,
                componentKey            : 0,
                countInfoKardex         : 0,
                frase                   : "Descargando archivo",
                overlayDesgarga         : false,
                showDialoginfo          : false,
                erroresModal            : [],
                dialogErrores           : false,
                isLoadingError          : false,
                dialogEmpleadoAsimilado : false,
                asimilados              : false,

                //variables para overlay de empleados
                empleadosPorcentaje     : 0,
                empleadosTotal          : 0,
                empleadosProcesados     : 0,
                procesados              : 0,
                conError                : 0,
                dialogMensaje           : false,
                dialogOverlayEmpleados  : false,

                claveEmpleado           : {
                    clave: null,
                    claveVerificacion: null,
                },
                dialogClave             : false,
                isLoadingChecador       : false,
                searchTipoEmpleado      : "ambos",
                itemsTiposEmpleados: [
                    { valor: "ambos", descripcion: "Ambos" },
                    { valor: "asalariado", descripcion: "Asalariado" },
                    { valor: "asimilado", descripcion: "Asimilado" },
                ],
                itemsCategoriasEmpleado     : [],
                searchCategoriaEmpleado     : null,
                itemsCentroCosto            : [],
                searchCentroDeCosto         : null,
                permisos :{
                    create: false,
                    update: false,
                    delete: false,
                }

            };
        },
        watch: {
            cliente_value: function (val) {
                this.cambiaCliente(val);
                this.getRegistroPatronal();
            },
            empresa_value: function (val) {
                this.cambiaEmpresa(val);
                if (val != null) {
                    this.mSucursal = true;
                    this.getTiposNominas();
                    this.getCentroCostos(val)
                } else {
                    this.mSucursal = false;
                }
            },
            sucursal_value: function (val) {
                this.cambiaSucursal(val);
                if (val != null) {
                    this.mDeptos = true;
                    this.mReloj = true;
                    this.mPuesto = true;
                } else {
                    this.mDeptos = false;
                    this.mReloj = false;
                    this.mPuesto = false;
                }
            },
        },
        methods: {
            open() {
                //this.$refs.fileDocument.$el.querySelector('input').click()
                //this.$refs.fileDocument.click()
                document.getElementById("fileDocument").click();
            },
            listar() {
                this.datosLogin = this.$session.get("usuario");
                this.rol = this.datosLogin.rol.name;
                this.urlImg = GetUrlImg.getURL();

                this.filters.rol_name = this.datosLogin.rol.name;
                this.filters.usuario_id = this.datosLogin.id;

                if (this.rol == "root") {
                    this.getClientes();
                }

                if (this.rol == "admin") {
                    this.cliente_value = this.datosLogin.cliente_id;
                    //this.filters.AND.push({column:'CLIENTE_ID', value:this.cliente_value});
                    this.filters.cliente_id = this.cliente_value;
                }

                if (this.rol == "admin-empresa") {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                    //this.filters.AND.push({column:'EMPRESA_ID', value:this.empresa_value});
                    this.filters.empresa_id = this.empresa_value;
                }

                if (this.rol == "admin-sucursal") {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id
                    this.sucursal_value = this.datosLogin.sucursal_id;
                    //this.filters.AND.push({column:'SUCURSAL_ID', value:this.sucursal_value});
                    this.filters.sucursal_id = this.sucursal_value;
                }

                if (this.rol == "admin-depto") {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id
                    this.departamento_value = this.datosLogin.departamento_id;

                    //this.filters.AND.push({column:'DEPARTAMENTO_ID', value:this.departamento_value});
                    this.filters.departamento_id = this.datosLogin.departamento_id;
                }
            },
            openModal(type, itemEmpleado) {
                let resolucion = screen.height;
                let alto = screen.height * 0.7;
                if (resolucion < 850) {
                    alto = resolucion * 0.76;
                }

                const title = type == "update" ? "Actualizar Empleado" : "Registrar Empleado";

                this.$modal.show(
                ComponentOne,
                    {
                        title: title,
                        objEmpleado: itemEmpleado,
                        action: type,
                        tabIndex: 0,
                    },
                    {
                        name: "kardexModal",
                        height: alto,
                        width: 900,
                        scrollable: true,
                    },
                    {
                        "before-close": (event) => {
                        this.isLoading = true;
                        this.$refs.tabla.getData();
                        },
                    }
                );
            },
            openModalInfo(itemEmpleado) {
                this.countInfoKardex = this.countInfoKardex + 1;
                this.showDialoginfo = true;
                this.objEmpleado = itemEmpleado;
                this.fotoModal = GetUrlImg.getURL() + itemEmpleado.foto;
                this.dialog_info = true;
            },
            getPaginationInfo(data) {
                switch (data.type) {
                case "page":
                    this.paginationData.numberPage = data.value;
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value;
                    break;
                }
                this.isLoading = true;
            },
            async getBajaImss() {
                let param = { activo: true, paginate: false };
                await apiTipoBajaImss.find(param).then((response) => {
                    this.tipos_baja_imss = response.data;
                })
                .catch((err) => {
                    console.error(err);
                    Notify.ErrorToast(
                    "Ocurrió un error al cargar los tipos de baja IMSS"
                    );
                });
            },
            async getClientes(clientes = null) {
                let param = { activo: true, paginate: false };
                await clientesApi.getClientes(param).then((response) => {
                    this.clientes = response.data;
                })
                .catch((err) => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los clientes");
                });
            },
            async cambiaCliente(cliente, accion = null) {
                if (cliente == null) {
                    return;
                }
                let param = { activo: true, paginate: false, cliente_id: cliente };
                await empresaApi.getEmpresas(param).then((response) => {
                    this.empresas = response.data;
                    //this.finaliza_parametros();
                })
                .catch((err) => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
                });
            },
            async cambiaEmpresa(empresa, accion = null) {
                if (empresa == null) {
                    return;
                }
                let parametros = { activo: true, paginate: false, empresa_id: empresa };
                await sucursalApi.getSucursales(parametros).then((response) => {
                    this.sucursales = response.data;
                    //this.finaliza_parametros();
                })
                .catch((err) => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
                });
            },
            async cambiaSucursal(sucursal, accion = null) {
                if (sucursal == null) {
                    return;
                }
                let parametros = {
                    paginate: false,
                    activo: true,
                    sucursal_id: sucursal,
                };
                await departamentoApi.getDepartamentos(parametros).then((response) => {
                    this.departamentos = response.data;
                    //this.finaliza_parametros();
                })
                .catch((err) => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los departamentos.");
                });

                let parametrosHorario = {
                    activo: true,
                    paginate: false,
                    sucursal_id: sucursal,
                };
                /*horariosApi
                    .getHorarios(parametrosHorario)
                    .then(response => {
                    this.horarios = response.data;
                    //this.finaliza_parametros();
                    })
                    .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los horarios.");
                    });*/

                /*let paramPuesto= { activo: true, paginate: false, order: "asc", sucursal_id : this.sucursal_value };
                await puestoApi
                    .getPuestos(paramPuesto)
                    .then(response => {
                    this.puestos = response.data;
                    })
                    .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los puestos.");
                    });
                */
            },
            async cambiaDepartamento(departamento, accion = null) {
                let parametros = {
                    activo: true,
                    paginate: false,
                    departamento_id: departamento,
                };
                /*await horariosApi
                    .getHorarios(parametros)
                    .then(response => {
                    this.horarios = response.data;
                    //this.finaliza_parametros();
                    })
                    .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los horarios.");
                    });*/
            },
            async getRegistroPatronal() {
                if (this.cliente_value == null) {
                    return;
                }
                let parametros = {
                    activo: true,
                    paginate: false,
                    order: "asc",
                    cliente_id: this.cliente_value,
                };
                await apiRegistroPatronal.find(parametros).then((response) => {
                    //this.registroPatronal = response.data;
                    let format = response.data;
                    if(response.data.length != 0){
                        format.forEach( item => {
                            item.nombreClave = item.nombre + ' - ' + item.clave
                            this.registroPatronal.push(item);
                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                    Notify.ErrorToast(
                    "Ocurrió un error al cargar los Registros Patronales."
                    );
                });
            },
            async loadModalData() {
                await this.resetValues();

                let cliente = null;
                let empresa = null;
                let sucursal = null;
                let depto = null;
                this.searchTipoEmpleado = "ambos";

                this.getCategoriaEmpleados();

                if (this.rol == "root") {
                    this.getClientes();
                }
                if (this.rol == "admin") {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.cambiaCliente(this.datosLogin.cliente_id, this.accion);
                    cliente = this.datosLogin.cliente_id;
                }
                if (this.rol == "admin-empresa") {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                    this.cambiaEmpresa(this.datosLogin.empresa_id, this.accion);
                    empresa = this.datosLogin.empresa_id;
                }
                if (this.rol == "admin-sucursal") {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                    this.sucursal_value = this.datosLogin.sucursal_id;
                    this.cambiaSucursal(this.datosLogin.sucursal_id, this.accion);
                    sucursal = this.datosLogin.sucursal_id;
                }
                if (this.rol == "admin-depto") {
                    depto = this.datosLogin.departamento_id;
                }
            },
            setFilters() {
                this.filters = {
                    rol_name        : null,
                    usuario_id      : null,
                    activo          : true,
                    paginate        : true,
                    include_padres  : true,
                    cliente_id      : null,
                    empresa_id      : null,
                    sucursal_id     : null,
                    departamento_id : null,
                    order_by        : "clave",
                    tipo_empleado   : "ambos",
                    min_fecha_ingreso: null,
                    max_fecha_ingreso: null,
                    centro_costo_id : null,
                    categoria_id    : null,
                };
                let fecha_inicio    = this.fecha_inicio;
                let fecha_final     = this.fecha_final;
                let cliente         = this.cliente_value;
                let empresa         = this.empresa_value;
                let sucursal        = this.sucursal_value;
                let departamento    = this.departamento_value;
                let tipoNomina      = this.tiposNominas_value;
                let registroPatronal= this.registroPatronal_value;
                let nombre          = this.nombre;
                let rfc             = this.rfc;
                let correo          = this.correo;
                let dates           = 0;

                this.filters.rol_name = this.datosLogin.rol.name;
                this.filters.usuario_id = this.datosLogin.id;

                if (this.rol == "admin") {
                    cliente = this.datosLogin.cliente_id;
                } else if (this.rol == "admin-empresa") {
                    empresa = this.datosLogin.empresa_id;
                } else if (this.rol == "admin-sucursal") {
                    sucursal = this.datosLogin.sucursal_id;
                } else if (this.rol == "admin-depto") {
                    departamento = this.datosLogin.departamento_id;
                }

                this.filters.tipo_empleado = this.searchTipoEmpleado;

                if (
                    fecha_inicio != null &&
                    fecha_inicio != undefined &&
                    fecha_inicio != ""
                ) {
                    this.filters.min_fecha_ingreso = fecha_inicio;
                }

                if (
                    fecha_final != null &&
                    fecha_final != undefined &&
                    fecha_final != ""
                ) {
                    this.filters.max_fecha_ingreso = fecha_final;
                }

                this.isLoading = true;

                if (cliente != null && cliente != undefined && cliente != "") {
                    this.filters.cliente_id = cliente;
                }

                if (sucursal != null && sucursal != undefined && sucursal != "") {
                    this.filters.sucursal_id = sucursal;
                }

                if (empresa != null && empresa != undefined && empresa != "") {
                    this.filters.empresa_id = empresa;
                }

                if (
                    departamento != null &&
                    departamento != undefined &&
                    departamento != ""
                ) {
                    this.filters.departamento_id = departamento;
                }

                if (tipoNomina != null && tipoNomina != undefined && tipoNomina != "") {
                    this.filters.tipo_nomina_id = tipoNomina;
                }

                if (
                    registroPatronal != null &&
                    registroPatronal != undefined &&
                    registroPatronal != ""
                ) {
                    this.filters.registro_patronal_id = registroPatronal;
                }

                if (nombre != null && nombre != undefined && nombre != "") {
                    this.filters.nombre_completo = nombre;
                }

                if (rfc != null && rfc != undefined && rfc != "") {
                    this.filters.rfc = rfc;
                }

                if (correo != null && correo != undefined && correo != "") {
                    this.filters.correo = correo;
                }

                let centroCosto = this.searchCentroDeCosto;
                if (centroCosto != null && centroCosto != undefined && centroCosto != "") {
                    this.filters.centro_costo_id = centroCosto;
                }

                let categoria = this.searchCategoriaEmpleado
                if (categoria != null && categoria != undefined && categoria != "") {
                    this.filters.categoria_id = categoria;
                }
            },
            cerrarModal() {
                this.dialog = false;
                this.dialogLayout = false;
                this.dialogLayoutEmpleados = false;
                this.isSaving = false;
                this.loading_guardar = false;
                this.dialog_info = false;

                this.resetValues();
            },
            async resetValues() {
                if (
                    this.$refs.FechaInicio != undefined ||
                    this.$refs.FechaInicio != null
                ) {
                    this.$refs.FechaInicio.resetDate();
                }
                if (
                    this.$refs.FechaFinal != undefined ||
                    this.$refs.FechaFinal != null
                ) {
                    this.$refs.FechaFinal.resetDate();
                }
                if (this.$refs.FechaBaja != undefined || this.$refs.FechaBaja != null) {
                    this.$refs.FechaBaja.resetDate();
                }
                this.$nextTick(() => {
                    if (this.$refs.form != undefined || this.$refs.form != null) {
                        this.$refs.form.reset();
                    }
                    if (this.rol == "root") {
                        this.cliente_value = null;
                        this.empresa_value = null;
                        this.sucursal_value = null;
                        this.departamento_value = null;
                        this.tiposNominas_value = null;
                        this.empresas = [];
                        this.sucursales = [];
                        this.departamentos = [];
                        this.registroPatronal = [];
                        this.tiposNominas = [];
                        this.itemsCentroCosto = [];
                        this.searchCentroDeCosto = null;
                    }

                    if (this.rol == "admin") {
                        this.empresa_value = null;
                        this.sucursal_value = null;
                        this.departamento_value = null;
                        this.sucursales = [];
                        this.departamentos = [];
                        this.tiposNominas_value = null;
                        this.itemsCentroCosto = [];
                        this.searchCentroDeCosto = null;
                    }

                    if (this.rol == "admin-empresa") {
                        this.sucursal_value = null;
                        this.departamento_value = null;
                        this.departamentos = [];
                        this.tiposNominas_value = null;
                        this.searchCentroDeCosto = null;
                    }

                    if (this.rol == "admin-sucursal") {
                        this.departamento_value = null;
                        this.tiposNominas_value = null;
                        this.searchCentroDeCosto = null;
                    }

                    this.fecha_inicio = null;
                    this.fecha_final = null;
                    this.nombre = null;
                    this.rfc = null;
                    this.correo = null;
                    this.registroPatronal_value = null;
                    this.baja = {
                        id: null,
                        rol_name: null,
                        accion: "Baja",
                        motivo: "",
                        fecha: "",
                        usuario_id: null,
                        email: "",
                        tipo_baja_imss_id: null,
                    };
                    this.archivo = {
                        archivo: null,
                        cliente_id: null,
                        empresa_id: null,
                        sucursal_id: null,
                        rol_name: null,
                        tipo: "nominas",
                    };
                    this.nombreBaja = "";
                    this.nombre_archivo = "";
                    this.itemsCategoriasEmpleado = [];
                    this.searchCategoriaEmpleado = null;
                
                });
            },
            abrirModal(accion, item = null) {
                this.getBajaImss();
                this.accion = accion;
                this.isLoading = true;
                this.tituloModal = "Baja del empleado";
                (this.baja.rol_name = this.datosLogin.rol.name),
                (this.nombreBaja = item.nombre_completo);
                this.baja.id = item.id;
                this.baja.usuario_id = this.datosLogin.id;
                this.baja.email = item.correo;
                this.datosEmpleado = item;
                this.isLoading = false;
                setTimeout(function () {
                    document.getElementById("btnModal").click();
                }, 100);
            },
            bajaEmpleado() {
                this.$refs.form.validate().then((success) => {
                this.isSaving = true;
                if (!success) {
                    this.isSaving = false;
                    return;
                }
                empleadosApi.eliminarEmpleado(this.baja).then((response) => {
                    this.cerrarModal();
                    Notify.Success(
                        "Baja de Colaborador",
                        "El colaborador ha sido dado de baja satisfactoriamente."
                    );
                    this.$refs.tabla.getData();
                })
                .catch((err) => {
                    if (typeof err.response.data.error === "string") {
                        Notify.ErrorToast(err.response.data.error);
                    } else {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                        leyenda += `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                    this.isSaving = false;
                    });
                });
            },
            eliminar(item) {
                Notify.Alert(
                    "¿Estás seguro que deseas eliminar a este empleado?",
                    "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                    "Eliminar",
                    () => {
                        let idEmpleado = item.id;
                        let accion = "Eliminar";
                        let usuario_id = this.datosLogin.id;
                        let param = {
                            rol_name: this.datosLogin.rol.name,
                            id: idEmpleado,
                            accion: accion,
                            usuario_id: usuario_id,
                            email: item.correo,
                        };
                        empleadosApi.eliminarEmpleado(param).then((response) => {
                            Notify.Success(
                                "Colaborador eliminado",
                                "El colaborador ha sido eliminado satisfactoriamente."
                            );
                            this.$refs.tabla.getData();
                        })
                        .catch((err) => {
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda += `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        });
                    },
                    null,
                    true,
                    true
                );
            },
            detalleEmpleado(data) {
                this.$session.remove("detalleEmpleado");
                this.$session.set("detalleEmpleado", data);
                this.$router.push("/kardex/detalleEmpleado");
            },
            async layout() {
                let self = this;
                let url = "empleados/layout";
                const FileDownload = require("js-file-download");
                var today = new Date();
                let nombre;
                if (this.rol == "admin" || this.rol == "root") {
                    this.isSaving = true;
                    nombre =
                        "layout_de_importacion_de_empleados_" +
                        today.getDate() +
                        "_" +
                        (today.getMonth() + 1) +
                        "_" +
                        today.getFullYear() +
                        "_" +
                        today.getHours() +
                        "-" +
                        today.getMinutes() +
                        "-" +
                        today.getSeconds() +
                        ".xlsx";
                    var object1 = {
                        rol_name: "admin-empresa",
                        cliente_id: this.cliente_value,
                        empresa_id: this.empresa_value,
                    };
                }
                if (this.rol == "admin-empresa") {
                    this.overlayDesgarga = true;
                    nombre =
                        "layout_de_importacion_de_empleados_" +
                        today.getDate() +
                        "_" +
                        (today.getMonth() + 1) +
                        "_" +
                        today.getFullYear() +
                        "_" +
                        today.getHours() +
                        "-" +
                        today.getMinutes() +
                        "-" +
                        today.getSeconds() +
                        ".xlsx";
                    var object1 = {
                        rol_name: this.rol,
                        cliente_id: this.datosLogin.cliente_id,
                        empresa_id: this.datosLogin.empresa_id,
                    };
                }
                if (this.rol == "admin-sucursal") {
                    this.overlayDesgarga = true;
                    nombre =
                        "layout_de_importacion_de_empleados_" +
                        today.getDate() +
                        "_" +
                        (today.getMonth() + 1) +
                        "_" +
                        today.getFullYear() +
                        "_" +
                        today.getHours() +
                        "-" +
                        today.getMinutes() +
                        "-" +
                        today.getSeconds() +
                        ".xlsx";
                    var object1 = {
                        rol_name: this.rol,
                        cliente_id: this.datosLogin.cliente_id,
                        empresa_id: this.datosLogin.empresa_id,
                        sucursal_id: this.datosLogin.sucursal_id,
                    };
                }
                object1.sistema = "nominas";
                try {
                    await Vue.axios({
                        method: "GET",
                        responseType: "blob",
                        url: url,
                        params: object1,
                    }).then((response, status, xhr) => {
                        FileDownload(response.data, nombre);
                        this.cerrarModal();
                        this.overlayDesgarga = false;
                    });
                } catch (e) {
                    this.isSaving = false;
                    console.log(e);
                }
            },
            modalLayout(accion) {
                this.accionLayout = accion;
                if (accion === "importar") {
                    this.titulo_modal_doc = "Subir layout para agregar empleados";
                } else if (accion === "actulizacionMasivo") {
                    this.titulo_modal_doc = "Actualización masiva";
                }
                this.archivo.cliente_id = this.datosLogin.cliente_id;
                this.archivo.empresa_id = this.datosLogin.empresa_id;
                if (this.rol == "admin-sucursal") {
                    this.archivo.sucursal_id = this.datosLogin.sucursal_id;
                }
                this.archivo.rol_name = this.datosLogin.rol.name;

                this.isLoading = false;

                setTimeout(function () {
                    document.getElementById("btnModalLayout").click();
                }, 300);
            },
            async abrirModalLayoutEmpleados(accion) {
                await this.resetValues();
                this.isLoading = true;
                this.accionLayout = accion;
                this.dialogLayoutEmpleados = true;
                if (accion === "descargar") {
                    this.tituloLayoutEmpleados =
                    "Descargar layout para agregar empleados";
                } else if (accion === "subir") {
                    this.tituloLayoutEmpleados = "Subir layout para agregar empleados";
                } else if (accion === "exportar") {
                    this.tituloLayoutEmpleados = "Exportar layout de empleados";
                } else if (accion === "exportar-asimilado") {
                    this.tituloLayoutEmpleados =
                    "Exportar layout de empleados asimilados";
                    this.asimilados = true;
                } else if (accion === "actulizacionMasivo") {
                    this.tituloLayoutEmpleados = "Actualización masiva";
                }
                if (this.rol == "admin") {
                    this.archivo.cliente_id = this.datosLogin.cliente_id;
                    this.cliente_value = this.datosLogin.cliente_id;
                }
                if (this.rol == "root") {
                    this.getClientes();
                }
                this.isLoading = false;
            },
            cerrarModalErrores() {
                this.dialogErrores = false;
                this.erroresModal = [];
            },
            guardarLayout() {
                let self = this;
                this.$refs.form.validate().then((success) => {
                    this.isSaving = true;
                    if (!success) {
                        this.isSaving = false;
                        return;
                    }
                    if (this.rol == "root" || this.rol == "admin" || this.rol == "admin-empresa") {
                        this.archivo.cliente_id = this.cliente_value;
                        this.archivo.empresa_id = this.empresa_value;
                        this.archivo.rol_name = "admin-empresa";
                    }else if(this.rol == 'admin-sucursal'){
                        this.archivo.rol_name = "admin-sucursal";
                    }

                    let formData = new FormData();
                    formData.append("archivo", this.archivo.archivo);
                    formData.append("cliente_id", this.archivo.cliente_id);
                    formData.append("empresa_id", this.archivo.empresa_id);
                    formData.append("tipo", this.archivo.tipo);

                    if (this.rol == "admin-sucursal") {
                        formData.append("sucursal_id", this.archivo.sucursal_id);
                    }

                    formData.append("rol_name", this.archivo.rol_name);
                    formData.append("usuario_id", this.datosLogin.id);
                    let url = "empleados/importar";
                    const FileDownload = require("js-file-download");
                    const config = {
                        headers: {
                            responseType: "blob",
                        },
                    };

                    this.loading_guardar = true;
                    Vue.axios({
                        method: "post",
                        url: "empleados/importar",
                        responseType: "blob",
                        data: formData,
                        config: { headers: { "Content-Type": "multipart/form-data" } },
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            Notify.ErrorToast("Se encontraron errores en los datos");
                            FileDownload(response.data, "reporte.xlsx");
                            self.cerrarModal();
                            self.$apollo.queries.empleados.refetch();
                        } else if (response.status == 202) {
                            Notify.Success("Operación exitosa");
                            self.cerrarModal();
                            self.$apollo.queries.empleados.refetch();
                        } else {
                            self.cerrarModal();
                            self.$apollo.queries.empleados.refetch();
                        }
                    })
                    .catch((err) => {
                        this.loading_guardar = false;
                        this.isSaving = false;
                        Notify.ErrorToast("Error al subir archivo.");
                        this.$refs.tabla.getData();
                    });
                });
            },
            guardarLayout2() {
                let self = this;
                this.$refs.form.validate().then((success) => {
                    this.isSaving = true;
                    if (!success) {
                        this.isSaving = false;
                        return;
                    }
                    if (this.rol == "root" || this.rol == "admin" || this.rol == "admin-empresa") {
                        this.archivo.cliente_id = this.cliente_value;
                        this.archivo.empresa_id = this.empresa_value;
                        this.archivo.rol_name = "admin-empresa";
                    }else if(this.archivo.rol_name){
                        this.archivo.rol_name = "admin-sucursal"
                    }
                    let formData = new FormData();
                    formData.append("archivo", this.archivo.archivo);
                    formData.append("cliente_id", this.archivo.cliente_id);
                    formData.append("empresa_id", this.archivo.empresa_id);
                    formData.append("tipo", this.archivo.tipo);

                    if (this.rol == "admin-sucursal") {
                        formData.append("sucursal_id", this.archivo.sucursal_id);
                    }

                    formData.append("rol_name", this.archivo.rol_name);
                    formData.append("usuario_id", this.datosLogin.id);
                    let url = "empleados/importar";
                    const FileDownload = require("js-file-download");
                    const config = {
                        headers: {
                        responseType: "blob",
                        },
                    };

                    this.loading_guardar = true;
                    Vue.axios({
                        method: "post",
                        url: "empleados/importar",
                        data: formData,
                        config: { headers: { "Content-Type": "multipart/form-data" } },
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            //Notify.Success("Operación exitosa");
                            self.cerrarModal();
                            self.dialogOverlayEmpleados = true;
                            //self.$apollo.queries.empleados.refetch();
                        } else if (response.status == 202) {
                            Notify.ErrorToast("Se encontraron errores en los datos");
                            self.erroresModal = response.data;
                            self.dialogErrores = true;
                            self.cerrarModal();
                            self.$apollo.queries.empleados.refetch();
                        } else {
                            self.cerrarModal();
                            self.$apollo.queries.empleados.refetch();
                        }
                    })
                    .catch((err) => {
                        self.isSaving = false;
                        self.dialogOverlayEmpleados = false;
                        Notify.ErrorToast("Error al subir archivo.");
                    });
                });
            },
            pickFile() {
                this.$refs.image.click();
            },
            onFilePicked(e) {
                const files = e.target.files;
                if (files[0] !== undefined) {
                    this.nombre_archivo = files[0].name;
                if (this.nombre_archivo.lastIndexOf(".") <= 0) {
                    return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                    this.archivo.archivo = files[0];
                });
                } else {
                    this.nombre_archivo = "";
                    this.imageUrl = "";
                }
            },
            abrirModalEmpleado(accion, item = null) {
                this.componentKey += 1;
                this.datosEmpleado = item;
                this.tituloEmpleado =
                accion == "update" ? "Actualizar empleado" : "Registrar empleado";
                this.accionEmpleado = accion;
                this.dialog_empleado = true;
            },
            abrirModalEmpleadoAsimilado(accion, item = null) {
                this.componentKey += 1;
                this.datosEmpleado = item;
                this.tituloEmpleado =
                accion == "update" ? "Actualizar empleado" : "Registrar empleado";
                this.accionEmpleado = accion;
                this.dialogEmpleadoAsimilado = true;
            },
            cerrarModalEmpleado(data) {
                this.datosEmpleado = null;
                if (data == "cancelar") {
                    this.dialog_empleado = false;
                    this.dialogEmpleadoAsimilado = false;
                }
                if (data == "guardarCerrar") {
                    this.dialog_empleado = false;
                    this.dialogEmpleadoAsimilado = false;
                    this.isLoading = true;
                    this.$refs.tabla.getData();
                }
            },
            async getTiposNominas() {
                let param = {
                    activo: true,
                    paginate: false,
                    cliente_id: this.cliente_value,
                    empresa_id: this.empresa_value,
                };
                this.tiposNominas = [];
                this.tiposNominas_value = null;
                await apiTipoNomina.find(param).then((response) => {
                    let format = response.data;
                    if (format.length != 0) {
                        format.forEach((item) => {
                            item.claveDescripcion = item.clave + " - " + item.descripcion;
                            this.tiposNominas.push(item);
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
                });
            },
            exportar() {
                let self = this;
                this.overlayDesgarga = true;
                let url = "empleados/exportar";
                const FileDownload = require("js-file-download");
                var today = new Date();
                let nombre;
                this.isSaving = true;

                if (this.rol == "root" || this.rol == "admin") {
                    this.$refs.form.validate().then((success) => {
                        if (!success) {
                            this.isSaving = false;
                            return;
                        }
                        nombre =
                            "empleados_" +
                            today.getDate() +
                            "_" +
                            (today.getMonth() + 1) +
                            "_" +
                            today.getFullYear() +
                            "_" +
                            today.getHours() +
                            "-" +
                            today.getMinutes() +
                            "-" +
                            today.getSeconds() +
                            ".xlsx";
                        var object1 = {
                            rol_name: "admin-empresa",
                            cliente_id: this.cliente_value,
                            empresa_id: this.empresa_value,
                            sistema: "nominas",
                        };

                        if (this.asimilados) {
                            object1.asimilados = true;
                            this.asimilados = false;
                        }

                        try {
                            Vue.axios({
                                method: "GET",
                                responseType: "blob",
                                url: url,
                                params: object1,
                            })
                            .then((response, status, xhr) => {
                                if (response.status == 200) {
                                    FileDownload(response.data, nombre);
                                    self.overlayDesgarga = false;
                                    this.cerrarModal();
                                } else {
                                    Notify.ErrorToast(
                                        "Algo ha salido mal, intenta de nuevo o consulta a soporte técnico."
                                    );
                                    self.overlayDesgarga = false;
                                    this.cerrarModal();
                                }
                            })
                            .catch((error) => {
                                if (error.response.status == 403) {
                                    if (error.response.headers["content-type"]) {
                                        if (
                                            error.response.headers["content-type"] == "sinEmpleados"
                                        ) {
                                            console.log(
                                            decodeURIComponent(
                                                escape(error.response.headers["content-type"])
                                            )
                                            );
                                            Notify.ErrorToast(
                                                "La empresa no contiene empleados para actualizar."
                                            );
                                        }
                                        self.overlayDesgarga = false;
                                        this.cerrarModal();
                                    } else {
                                        console.log(error.response);
                                    }
                                } else {
                                    console.log(error.response);
                                    Notify.ErrorToast(
                                        "Algo ha salido mal, intenta de nuevo o consulta a soporte técnico."
                                    );
                                }
                            });
                        } catch (e) {
                            self.overlayDesgarga = false;
                            this.isSaving = false;
                            console.log(e);
                        }
                    });
                } else {
                    nombre =
                        "empleados_" +
                        today.getDate() +
                        "_" +
                        (today.getMonth() + 1) +
                        "_" +
                        today.getFullYear() +
                        "_" +
                        today.getHours() +
                        "-" +
                        today.getMinutes() +
                        "-" +
                        today.getSeconds() +
                        ".xlsx";
                    var object1 = {
                        rol_name: this.rol,
                        cliente_id: this.datosLogin.cliente_id,
                        empresa_id: this.datosLogin.empresa_id,
                        sucursal_id: this.datosLogin.sucursal_id,
                        sistema: "nominas",
                    };

                    if (this.asimilados) {
                        object1.asimilados = true;
                        this.asimilados = false;
                    }

                    try {
                        Vue.axios({
                            method: "GET",
                            responseType: "blob",
                            url: url,
                            params: object1,
                        }).then((response, status, xhr) => {
                            FileDownload(response.data, nombre);
                            self.overlayDesgarga = false;
                            this.cerrarModal();
                        });
                    } catch (e) {
                        self.overlayDesgarga = false;
                        this.isSaving = false;
                        console.log(e);
                    }
                }
            },
            actulizacionMasivo() {
                let self = this;
                this.$refs.form.validate().then((success) => {
                    this.isSaving = true;
                    if (!success) {
                        this.isSaving = false;
                        return;
                    }
                    if (this.rol == "root" || this.rol == "admin" || this.rol == "admin-empresa") {
                        this.archivo.cliente_id = this.cliente_value;
                        this.archivo.empresa_id = this.empresa_value;
                        this.archivo.rol_name = "admin-empresa";
                    }else if(this.rol == 'admin-sucursal'){
                      this.archivo.rol_name = 'admin-sucursal'
                    }
                    let formData = new FormData();
                    formData.append("archivo", this.archivo.archivo);
                    formData.append("cliente_id", this.archivo.cliente_id);
                    formData.append("empresa_id", this.archivo.empresa_id);
                    formData.append("tipo", "nominas");

                    if (this.rol == "admin-sucursal") {
                        formData.append("sucursal_id", this.archivo.sucursal_id);
                    }

                    formData.append("rol_name", this.archivo.rol_name);
                    formData.append("usuario_id", this.datosLogin.id);
                    const FileDownload = require("js-file-download");
                    const config = {
                        headers: {
                        responseType: "blob",
                        },
                    };

                    Vue.axios({
                        method: "post",
                        url: "empleados/update-masivo",
                        data: formData,
                        config: { headers: { "Content-Type": "multipart/form-data" } },
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            //Notify.Success("Operación exitosa", "Se actualizaron correctamente los empleados");
                            self.cerrarModal();
                            self.dialogOverlayEmpleados = true;
                            //self.$apollo.queries.empleados.refetch();
                        } else if (response.status == 202) {
                            Notify.ErrorToast("Se encontraron errores en los datos");
                            console.log(response.data);
                            self.erroresModal = response.data;
                            self.dialogErrores = true;
                            self.cerrarModal();
                            self.$apollo.queries.empleados.refetch();
                        } else {
                            self.cerrarModal();
                            self.$apollo.queries.empleados.refetch();
                        }
                    })
                    .catch((err) => {
                        self.isSaving = false;
                        self.dialogOverlayEmpleados = false;
                        Notify.ErrorToast("Error al subir archivo.");
                    });
                });
            },
            socketProcesandoEmpleados() {
                window.Echo = new Echo({
                    broadcaster: "pusher",
                    key: "ASDASD2121",
                    wsHost: env.serverURL.url,
                    wsPort: env.pusherPort,
                    wssPort: env.pusherPort,
                    forceTLS: env.serverURL.tls,
                    disableStats: true,
                    enabledTransports: ["ws", "wss"],
                });

                window.Echo.channel(
                    `progreso-importar-empleado-${this.datosLogin.id}`
                ).listen("ImportarEmpleadoProgress", (e) => {
                    this.dialogOverlayEmpleados = true;
                    this.empleadosPorcentaje = e.message.porcentaje;
                    this.empleadosTotal = e.message.total;
                    this.empleadosProcesados = e.message.procesados;
                    if (e.message.porcentaje == 100) {
                        this.procesados = e.message.procesados;
                        this.conError = e.message.errores;
                        this.dialogMensaje = true;

                        if (e.message.errores.length == 0) {
                            this.dialogOverlayEmpleados = false;
                        if (e.message.procede == false) {
                            Notify.ErrorToast(
                                "No se detecto ningún registro en el archivo."
                            );
                        } else {
                            Notify.Success("Operación exitosa");
                        }

                        this.$refs.tabla.getData();
                        } else {
                            this.dialogOverlayEmpleados = false;
                            this.erroresModal = e.message.errores;
                            this.dialogErrores = true;
                            this.$refs.tabla.getData();
                        }

                        setTimeout(() => {
                            this.resetProgresoEmpleados();
                        }, 5000);
                    }
                });
            },
            resetProgresoEmpleados() {
                this.empleadosPorcentaje = 0;
                this.empleadosTotal = 0;
                this.empleadosProcesados = 0;
                this.procesados = 0;
                this.conError = 0;
            },
            redireccionar() {
                this.$router.push("/dashboard");
            },
            async abrirModalClave(empleado) {
                this.isLoadingChecador = true;

                this.claveEmpleado.id = empleado.id;
                this.claveEmpleado.clave = empleado.clave;

                await this.getClave(empleado.id);

                this.dialogClave = true;
                this.isLoadingChecador = false;
            },
            cerrarModalClave() {
                this.claveEmpleado.id = null;
                this.claveEmpleado.clave = null;
                this.claveEmpleado.claveVerificacion = null;
                this.dialogClave = false;
            },
            async getClave(idEmpleado) {
                await apiRelojChecador.getCodigo(idEmpleado).then((response) => {
                    this.claveEmpleado.claveVerificacion = response.codigo_verificacion;
                })
                .catch((err) => {
                    console.log(err);
                    this.claveEmpleado.claveVerificacion = null;
                });
            },
            async generarClave() {
                let parametros = {
                    empleado_id: this.claveEmpleado.id,
                };
                await apiRelojChecador.generarCodigo(parametros).then((response) => {
                    Notify.SuccessToast("Generación de clave exitosa.");
                    this.getClave(this.claveEmpleado.id);
                })
                .catch((err) => {
                    console.log(err);
                    this.claveEmpleado.claveVerificacion = null;
                    Notify.ErrorToast(
                        "Algo ha salido mal, intenta de nuevo o consulta a soporte técnico."
                    );
                });
            },
            async getCategoriaEmpleados() {
                let param = {
                    activo      : true,
                    paginate    : false,
                    order       : 'asc'
                    
                }
                await apiCategoriaEmpleado.find(param).then((response) => {
                    this.itemsCategoriasEmpleado = response.data;
                })
                .catch (err => {
                    console.log(err);
                    Notify.ErrorToast("No se pudieron cargar las categorías de empleado.");
                })
            },
            async getCentroCostos(empresaId) {
                let param = {
                    activo      : true,
                    paginate    : false,
                    order       : 'asc',
                    empresa_id  : empresaId
                }
                await apiCentroCosto.find(param).then((response) => {
                    this.itemsCentroCosto = response.data;
                })
                .catch (err => {
                    console.log(err);
                    Notify.ErrorToast("No se pudieron cargar los centros de costo.");
                })
            },
            detalle_documentos(data) {
                this.$session.remove("documentos");
                this.$session.set("documentos", data);
                this.$router.push("/documentos");
            },
            permisosVista(){
                let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
                this.permisos.create = permisosStorage.includes("empleados.store")
                this.permisos.update = permisosStorage.includes("empleados.update")
                this.permisos.delete = permisosStorage.includes("empleados.delete")
            }
        },
        created() {
            this.listar();
            this.permisosVista();
        },
        mounted() {
            this.socketProcesandoEmpleados();
        },
        updated() {
            document.querySelector("#tableScroll thead tr th:nth-child(1)").style.paddingRight = "1px";
            document.querySelector("#tableScroll thead tr th:nth-child(1)").style.width = "160px";
            document.querySelector("#tableScroll thead tr th:nth-child(2) div").style.marginRight = "1px";
            document.querySelector("#tableScroll thead tr th:nth-child(2) div").style.margingLeft = "1px";
            document.querySelector("#tableScroll thead tr th:nth-child(5)").style.width = "310px";
            document.querySelector("#tableScroll thead tr th:nth-child(5)").style.paddingRight = "8px";
            document.querySelector("#tableScroll thead tr th:nth-child(5)").style.paddingLeft = "8px";
        },
    };
</script>

<style scoped>
    /*modal info*/
    .divider-blue {
        z-index: 999 !important;
        max-width: 102px;
        border-radius: 50px;
        background-color: #2db9d1;
        border: 1.5px solid #2db9d1;
    }
    .divider-gray {
        margin-left: -1px;
        border: 1.5px solid #e0e0e0;
        background: #e0e0e0;
    }
    .skiny {
        margin-top: 1px;
        border: 0.5px solid #e0e0e0;
    }
    .header {
        width: 435px;
        text-align: left !important;
        margin-bottom: 15px;
    }

    .title {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 22px !important;
        line-height: 27px !important;
        letter-spacing: 2px !important;
        color: #333333 !important;
    }
    .imgFoto {
        width: 100%;
        height: 100%;
    }
    .imgMarco {
        width: 191px;
        height: 199px;
        padding: 18px;
        border: 3px solid #e0e0e0;
        box-sizing: border-box;
    }
    /*modal info*/

    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        margin-top: -6%;
    }
    .titulo {
        font-family: "Montserrat" !important;
        font-size: 28px !important;
        font-weight: 700 !important;
        color: #0f3043 !important;
        letter-spacing: -0.68px;
        line-height: 34px;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0, 0, 0, 0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0, 0, 0, 0.54);
    }

    .btnBaja {
        font-family: "Montserrat";
        font-size: 13px !important;
        font-weight: 700 !important;
        background-color: #e57373 !important;
        border-radius: 23px 23px 23px 23px;
        color: white !important;
        width: 70% !important;
        height: 40px !important;
    }
    .cursor:hover {
        cursor: pointer;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        display: none;
    }
    #lateral .v-btn--example {
        bottom: 0;
        position: absolute;
        margin: 0 0 16px 16px;
    }
    .cursor-pointer {
        cursor: pointer;
    }

    .subtitle {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
    }
    .text {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
        text-transform: uppercase;
    }
    .menuClassAcciones {
        /* box-shadow: none; */
        border-radius: 10px !important;
        margin-top: 15px;
    }
    .btnAcciones {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background: #58c5d8 !important;
        color: #ffffff !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .btnAcciones:hover {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background: #58c5d8 !important;
        color: #ffffff !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
    }
    .btnAccionesContenedor {
        width: 300px;
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: #fff;
    }
    .btnAddContenedor {
        width: 250px;
        height: 140px;
        padding-top: 30px;
        background-color: #fff;
    }
    div.btnAccionesTitulo {
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesTitulo span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        color: #1e2245;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #f6f6f8;
    }

    #btnModalErrores {
        display: none;
    }

    .titleModalErrores {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #f6f6f6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999a;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1e2245;
        text-align: left !important;
    }
    .textoTablaError02 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #eb5757;
        line-height: 17px;
        text-align: left !important;
    }
    .trDivider {
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: #c4c4c4;
    }
    .menuClassAccionesEmpleado {
        border-radius: 10px !important;
    }

    .textoTablaClave01 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaClave02 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1e2245;
        text-align: left !important;
    }
</style>
